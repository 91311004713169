<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
      >
        <!-- setHeight="500px" -->
        <template v-slot:default="tab">
          <component
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'inout-statisical-investigation-hardcoding',
  props: {
  },
  data() {
    return {
      tab: 'statistics',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 통계조사표
        { name: 'statistics', icon: 'trending_up', label: '통계조사표', component: () => import(`${'./statisticsHardcoding.vue'}`) },
        // 배출량조사표
        { name: 'emissions', icon: 'factory', label: '배출량조사표', component: () => import(`${'./emissionsHardcoding.vue'}`) },
      ]
    },

  }
};
</script>